import React from "react";
import current_year from "../Functions/CurrentYear";

export function Footer() {
    return(
      
<footer
  className="flex flex-col items-center text-center text-black pb-6 pt-10 px-16 z-10 bg-white">
    <hr className="h-2 w-full bg-grey-400"/>
  <div className="w-full p-4 text-center font-quicksand">
    ©{current_year} Copyright Gravitas
  </div>
</footer>
    )
}