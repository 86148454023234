import { Footer } from "../Components/Footer";
import React, { useRef, useEffect, useState } from "react";
import Gravitas_intro from "../Images/Gravitas_intro.jpg";
import Warehouse from "../Images/warehouse.webp";
import Logistics from "../Images/Logistics.jpg";
import Software from "../Images/Software.jpg";
import Management from "../Images/Management.jpg";
import { motion } from "framer-motion"
import marioimg from "../Images/Nuestro equipo/Mario.jpg";
import counseling from "../Images/Counseling.jpg";
import rafaelaimg from "../Images/Nuestro equipo/Rafaela.jpg";
import saulimg from "../Images/Nuestro equipo/Saul.jpg";
import rafiimg from "../Images/Nuestro equipo/Rafael.jpeg";
import Video from "../Videos/background.mp4";
import ScrollingText from "../Components/Scrollertext";

import { Link } from 'react-router-dom';

import 'animate.css';
import { useInView } from 'react-intersection-observer';



export default function Homepage() {
    const Warehouseimg = Warehouse;
    const Softwareimg = Software;
    const Managementimg = Management;
    const Logisticimg = Logistics;
    const Counselingimg = counseling;

    const Profileimg1 = marioimg;
    const Progileimg2 = rafaelaimg;
    const Profileimg3 = saulimg;
    const Profileimg4 = rafiimg;

 
    const { ref: ref1, inView: inView1 } = useInView({
        triggerOnce: true,
        threshold: 0.1,
    });

    const { ref: ref2, inView: inView2 } = useInView({
        triggerOnce: true,
        threshold: 0.1,
    });

    const { ref: ref3, inView: inView3 } = useInView({
        triggerOnce: true,
        threshold: 0.1,
    });

    const { ref: ref4, inView: inView4 } = useInView({
        triggerOnce: true,
        threshold: 0.1,
    });

    const { ref: ref5, inView: inView5 } = useInView({
        triggerOnce: false,
        threshold: 0.1,
    });

    const sectionRef = useRef(null);
    const [isVisible, setIsVisible] = useState(false);

    const handleScroll = () => {
        if (sectionRef.current) {
            const rect = sectionRef.current.getBoundingClientRect();
            const windowHeight = window.innerHeight;
            // Check if any part of the section is within the viewport
            const isSectionVisible = rect.top < windowHeight && rect.bottom > 0;
            setIsVisible(isSectionVisible);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        handleScroll(); // Check visibility on initial load
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);
    return (
        <div id="inicio" className="flex flex-col w-full h-auto ">
            <div  ref={sectionRef} className="flex items-center h-screen w-full pt-10 overflow-hidden">
                <video  className={`fixed inset-0 w-full h-full object-cover ${isVisible ? 'fixed' : 'absolute'}`} src={Video} loop autoPlay muted controls={false} onContextMenu={(e) => e.preventDefault()} playsInline></video>
                <div ref={ref1} className="w-full h-full flex flex-col-reverse lg:flex-row items-center justify-center md:px-10 px-7 z-10 ">
                    <div className="flex flex-col justify-center items-center w-full lg:w-1/2 px-10">
                        <div className="">
                            <h1 className={`text-2xl sm:text-4xl md:text-4xl lg:text-4xl font-quicksand font-semibold text-center lg:text-start text-black ${inView1 ? 'animate__animated animate__fadeInLeft animate__delay-0.5s' : 'animate__animated animate__fadeOut'}`}>
                                Somos
                            </h1>
                            <h1 className={`pt-4 text-2xl sm:text-4xl md:text-4xl lg:text-6xl font-quicksand font-bold text-center lg:text-start text-black ${inView1 ? 'animate__animated animate__fadeInLeft animate__delay-1s' : 'animate__animated animate__fadeOut'}`}>
                                Gravitas
                            </h1>
                            <h1 className={`pt-4 text-2xl sm:text-4xl md:text-3xl font-quicksand font-normal text-center lg:text-start text-black ${inView1 ? 'animate__animated animate__fadeInLeft animate__delay-2s' : 'animate__animated animate__fadeOut'}`}>
                                Con Integridad y compromiso, llevamos a cabo nuestro deber
                            </h1>
                            <div className="pt-4 flex justify-center lg:justify-start">
                                <Link to="/sobrenosotros"
                                    className={`relative inline-flex items-center justify-center px-3.5 py-2.5 overflow-hidden font-medium transition duration-300 ease-out border-2 border-black rounded-lg shadow-md group ${inView1 ? 'animate__animated animate__bounceInUp animate__delay-3s' : 'animate__animated animate__fadeOut'}`}>
                                    <span
                                        className="absolute inset-0 flex items-center justify-center w-full h-full text-white duration-300 -translate-x-full bg-black group-hover:translate-x-0 ease">
                                        <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M14 5l7 7m0 0l-7 7m7-7H3"></path>
                                        </svg>
                                    </span>
                                    <span
                                         className="absolute flex items-center text-base font-semibold justify-center w-full h-full transition-all duration-300 transform group-hover:translate-x-full ease">
                                        Conocer más
                                    </span>
                                    <span className="relative text-base font-semibold invisible">Conocer Mas</span>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="w-full lg:w-1/2 px-10 py-10">
                        <img className={`h-44 lg:h-96 w-full rounded-xl drop-shadow-md ${inView1 ? 'animate__animated animate__fadeInRight animate__delay-2s' : 'animate__animated animate__fadeOut'}`} src={Gravitas_intro} alt="" />
                    </div>
                </div>
            </div>

            <div ref={ref2} className="w-full px-14 flex flex-col h-auto md:h-full lg:h-full xl:h-full bg-white  justify-center items-center gap-12 z-10 overflow-hidden" >
                <div className="pt-16 w-full text-center xl:text-start">
                    <h1 className={`text-3xl md:text-6xl lg:text-6xl xl:text-6xl font-quicksand font-bold ${inView2 ? 'animate__animated animate__fadeInRight' : 'animate__animated animate__fadeOut'}`}>Nuestros Servicios</h1>
                </div>

                <div className="grid grid-cols-1 xl:grid-cols-2 gap-10">

                    <div className="flex flex-col h-auto xl:h-64">
                        <h1 className={`text-4xl font-quicksand font-semibold text-slate-700 ${inView2 ? 'animate__animated animate__fadeInLeft animate__delay-1s' : 'animate__animated animate__fadeOut'}`}>Consultoria</h1>
                        <p className={`pt-2 xl:pt-6 font-quicksand text-lg md:text-xl lg:text-xl text-slate-500 ${inView2 ? 'animate__animated animate__fadeInLeft animate__delay-2s' : 'animate__animated animate__fadeOut'}`}>
                            Nos enfocamos en analizar, optimizar y mejorar los procesos empresariales de logisticas, manteniendo una estrecha relacion con los stackeholders y clientes.
                        </p>
                    </div>
                    <div className="flex h-64 row-start-1 xl:row-start-auto">
                        <img className={`w-full rounded-xl object-cover ${inView2 ? 'animate__animated animate__fadeInRight animate__delay-2s' : 'animate__animated animate__fadeOut'}`} src={Counselingimg}></img>
                    </div>

                    <div ref={ref3} className="flex flex-col h-auto xl:h-64">
                        <h1 className={`text-4xl font-quicksand font-semibold text-slate-700 ${inView3 ? 'animate__animated animate__fadeInLeft animate__delay-1s' : 'animate__animated animate__fadeOut'}`}>Proveedor de software</h1>
                        <p className={`pt-2 xl:pt-6 font-quicksand text-lg md:text-xl lg:text-xl text-slate-500 ${inView3 ? 'animate__animated animate__fadeInLeft animate__delay-2s' : 'animate__animated animate__fadeOut'}`}>
                            Proveedor de software de logística que se especializa en optimizar procesos empresariales.
                        </p>
                    </div>
                    <div className="flex row-start-3 xl:row-start-auto h-64">
                        <img className={`w-full rounded-xl object-cover ${inView3 ? 'animate__animated animate__fadeInRight animate__delay-2s' : 'animate__animated animate__fadeOut'}`} src={Softwareimg}></img>
                    </div>

                    <div ref={ref4} className="flex flex-col h-auto xl:h-64">
                        <h1 className={`text-4xl font-quicksand font-semibold text-slate-700 ${inView4 ? 'animate__animated animate__fadeInLeft animate__delay-1s' : 'animate__animated animate__fadeOut'}`}>Gestiones</h1>
                        <p className={`pt-2 xl:pt-6 font-quicksand text-lg md:text-xl lg:text-xl text-slate-500 ${inView4 ? 'animate__animated animate__fadeInLeft animate__delay-2s' : 'animate__animated animate__fadeOut'}`}>
                            Proveedor de soluciones de gestión de almacenes y flotas vehiculares
                        </p>
                    </div>
                    <div className="flex row-start-5 xl:row-start-auto h-64">
                        <img className={`w-full rounded-xl object-cover ${inView4 ? 'animate__animated animate__fadeInRight animate__delay-2s' : 'animate__animated animate__fadeOut'}`} src={Managementimg}></img>
                    </div>

                </div>
            </div>
            <div className="py-10 w-full bg-white z-10">
                     <ScrollingText/>
            </div>

            <div className=" w-full h-auto xl:h-screen px-10 xl:px-0 flex flex-col bg-white justify-center items-center gap-12 z-10 overflow-hidden">

                <div ref={ref5} className="grid grid-cols-1 xl:grid-cols-2 h-auto xl:h-4/5 gap-10 xl:px-0">

                    <div className={`w-auto h-80 xl:h-auto bg-center rounded-xl xl:rounded-r-xl ${inView5 ? 'animate__animated animate__bounceInLeft animate__delay-1s' : 'animate__animated animate__fadeOut'}`} style={{ backgroundImage: `url(${Logisticimg})`, backgroundSize: 'cover'}}>
                    </div>
                    <div className="flex flex-col items-center justify-center p-0 xl:p-12">
                        <h1 className={`text-5xl font-bold font-quicksand w-full text-center xl:text-start ${inView5 ? 'animate__animated animate__bounceInDown animate__delay-1s' : 'animate__animated animate__fadeOut'}`}>Nosotros</h1>
                        <h2 id="text" className={`pt-2 xl:pt-10 sm:text-md md:text-2xl lg:text-3xl font-quicksand  text-black contrast-300 text-justify text-balance ${inView5 ? 'animate__animated animate__bounceInRight animate__delay-2s' : 'animate__animated animate__fadeOut'}`}>
                            Gravitas se destaca por su compromiso con la excelencia, la innovación y la integridad. Nuestra misión es ofrecer soluciones avanzadas que anticipen las necesidades futuras de nuestros clientes. Nos esforzamos por liderar la industria mediante la entrega de resultados excepcionales.
                        </h2>
                    </div>

                </div>
            </div>
           

            <div className="w-full px-8 sm:px-0 md:px-0 flex flex-col h-auto xl:h-screen  items-center bg-white gap-12 z-10" >
                <div className=" w-full p-2 pt-6 m-2  flex items-center justify-center">
                    <h2 className="text-4xl xl:text-6xl font-quicksand font-bold">
                        Nuestro equipo
                    </h2>
                </div>
                <div className="w-2/3 md:h-2/3 grid grid-cols-4 grid-rows-2  justify-items-center">
                    <div className="md:col-span-2  col-span-4 flex-col m-5 flex justify-center items-center h-44 w-36">
                        <div className="rounded-full bg-blue-200 h-28 w-28">
                            <img className="rounded-full shadow-md" src={Profileimg4} alt="" />
                        </div>
                        <p className="mt-2 font-quicksand font-semibold">Rafael Luciano</p>
                        <p className="mt-2 font-quicksand text-sm">CEO</p>

                    </div>
                    <div className="md:col-span-2  col-span-4 flex-col m-5 flex justify-center items-center h-44 w-36">
                        <div className="h-28 w-28">
                            <img className="rounded-full shadow-md" src={Progileimg2} alt="" />
                        </div>
                        <p className="mt-2 font-quicksand font-semibold">Rafaela Mercedes</p>
                        <p className="mt-2 font-quicksand text-sm">Directora Comercial</p>

                    </div>
                    <div className="md:col-span-2  col-span-4 flex-col m-5 flex justify-center items-center h-44 w-36">
                        <div className="h-28 w-28">
                            <img className="rounded-full shadow-md" src={Profileimg1} alt="" />
                        </div>
                        <p className="mt-2 font-quicksand font-semibold">Mario Viñas</p>
                        <p className="mt-2 font-quicksand text-sm">Junior Developer</p>

                    </div>
                    <div className=" md:col-span-2  col-span-4 flex-col m-5 flex justify-center items-center h-44 w-36">
                        <div className="h-28 w-28">
                            <img className="h-28 w-28 rounded-full shadow-md" src={Profileimg3} alt="" />
                        </div>
                        <p className="mt-2 font-quicksand font-semibold">Saul Pinales</p>
                        <p className="mt-2 font-quicksand text-sm">Senior Developer</p>

                    </div>

                </div>
            </div>
           


            <Footer />
        </div>
    );
}

