import React from "react";

const ScrollingText = () => {
  // Create a list of spans with "Gravitas" repeated
  const spans = Array.from({ length: 50 }, (_, i) => (
    <span key={i} className="text-span text-xl font-bold pr-14 text-blue-900">Gravitas</span>
  ));

  return (
    <div className="overflow-hidden relative"> 
      <div className="animate-marquee whitespace-nowrap flex items-center">
        {spans}
      </div>
      <div className="animate-marquee-reverse whitespace-nowrap flex items-center mt-2"> 
        {spans}
      </div>
    </div>
  );
};

export default ScrollingText;
